$(function(){
    
    $('#placeDetails').on("click", 'a.showButton', function(e){
        console.log( this.closest)
        $(this).closest('article.places').toggleClass('showmore');
    });
    
    
    var appends ="";
     $.getJSON('js/data.json', function(jsonData) {
         console.log(jsonData);
        var data =  jQuery.parseJSON(JSON.stringify(jsonData));
        $.each(data, function(index) {
           var dataArr = data[index];
            var htmls = ` <article class="places" style="background:${dataArr.bg}">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 offset-md-2">
                            <div class="image"><img src="img/${dataArr.slug}.jpg" class="img-fluid" alt="${dataArr.title}"></div>
                        </div>
                    </div>
                    <div class="details">
                        <h2 class="titles">${dataArr.title}</h2>
                        <div class="showingtext">
                            <div class="someContent">
                               ${dataArr.topTex}
                            </div>
                        </div>
                    </div>
                    <div class="hiddenContent"><div class="moreContent">${dataArr.content}</div></div>
                    <a class="showButton"><span></span></a>
                </div>
            </article>`;
            appends += htmls;
        });
         $('#placeDetails').html(appends);
    });
    
    
    
    
});

